import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import KidneybeansList from './KidneybeansList'

const kidneybeans= () => {
  return (
    <RoomConsumer>
    {(value) => {
      const { loading, kidneybeans } = value
      
      if (loading) {
        return <Loading />
      }
      return (
        <div>
           <KidneybeansList kidneybeans={kidneybeans} />
        </div>
      )
    }}
  </RoomConsumer>
  )
}

export default kidneybeans