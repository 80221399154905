import React, { Component } from "react";
import logo from "../images/logo.png";
import { FaAlignRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import NavbarOne from "./NavbarOne";

export default class Navbar extends Component {
  state = {
    isOpen: false,
  };
  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <>
      <nav className="navbar">
      <NavbarOne/>
        <div className="nav-center">
          <div className="nav-header">
            <Link to="/">
              <img src={logo} alt="ANKARMARC GROUP" className="img" />
            </Link>
            <Link to="/" style={{ textDecoration: 'none' }}>
            <span className="mainContent color_group" >ANKAMARC</span>
            <span className="color_group">GROUP</span>
            </Link>
            <button
              type="button"
              className="nav-btn"
              onClick={this.handleToggle}
            >
              <FaAlignRight className="nav-icon" />
            </button>
          </div>
          <ul
            className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}
          >
            {/* <Link to='/'>Home</Link> */}
            <Link to="/">Home</Link>
            <Link to="/products">Products</Link>
            <Link to="/about">About us</Link>
            <Link to="/quotation">Request Quotation</Link>
           
          </ul>
          <span className="dropdown category">
              <span>Categories</span>
              <div className="dropdown-content">
                <Link to={`/products/nuts`}>Nuts</Link>
                <Link to="/products/steel">Steel</Link>
                <Link to="/products/charcol">Charcoal</Link>
                <Link to="/products/wood">Wood</Link>
                <Link to="/products/dates">Dates</Link>
                <Link to="/products/driedapricot">Driedapricot</Link>
                <Link to="/products/kidneybeans">Kidneybeans</Link>
                <Link to="/products/corn">Corn</Link>
                <Link to="/products/sunflowe-oil">SunFlower Oil</Link>
                <Link to="/products/dryfig">Dry fig</Link>
                <Link to="/products/locustbean">Locust bean gum</Link>
                <Link to="/products/sugar">ICUMSA SUGAR</Link>
                <Link to="/products/flower">Flour</Link>
                <Link to="/products/olive">Olive Oil</Link>
                <Link to="/products/cornoil">Corn Oil</Link>
                <Link to="/products/food-additives">Food Additives</Link>
                <Link to="/products/sunflower-seeds">SunFlower Seed</Link>
              </div>
            </span>
        </div>
      </nav>
      </>
    );
  }
}
